.topBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  padding: 10px 20px;
  color: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-sizing: border-box;
  border-bottom: 0px solid rgba(255, 255, 255, 0.7);
  height: 60px; /* Set a fixed height for the navbar */
}

.dark {
  background-color: rgba(0, 0, 0, 0.6);
}

.topBarNav {
  display: flex;
  align-items: center;
}

.siteName {
  font-size: 21.5px;
  font-weight: 400;
  padding: 0px;
  padding-right: 20px;
  position: relative;
  background: linear-gradient(45deg, #7d35d3, #845dba);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; /* Fallback for browsers that don't support background-clip: text */
  border: none;
  cursor: pointer;
}

.logoContainer {
  display: flex;
  align-items: center;
}

@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.crystalBall {
  vertical-align: middle;
  margin-right: 1.5px;
  margin-bottom: 3px;
  animation: fadeInFromTop 1s ease-out;
}

.logoImage {
  vertical-align: middle;
  margin-left: 1.5px;
  margin-bottom: 3px;
  height: 24px;
  max-height: 40px; /* Ensure the logo does not exceed the navbar height */
  animation: fadeInFromTop 1s ease-out;
}

.pricingButton {
  background: transparent;
  border: 2px solid #4a148c;
  color: rgb(239, 235, 245);
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin: 4px 20px 4px 0;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 40px;
  position: relative;
}

.pricingButton:hover {
  background: rgba(94, 53, 177, 0.1);
  cursor: pointer;
}

.pricingButton::after {
  content: "";
  position: absolute;
  right: -27px;
  top: 50%;
  transform: translateY(-50%);
  height: 90%;
  width: 0.7px;
  background-color: white;
}

.headerButton {
  color: white;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(98, 58, 178, 0.47);
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  outline: none;
  margin-left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.headerButton:hover {
  background-color: rgba(106, 27, 154, 0.8);
}

.headerButton:active {
  background-color: rgba(63, 14, 98, 0.9);
  transform: scale(0.95);
}

.userInitialDropdownMenu {
  display: none;
  position: absolute;
  background-color: #2a2a2a;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 20px;
  top: 60px;
  padding: 10px;
  border-radius: 4px;
}

.userInitialDropdownMenu a {
  color: #ffffff;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.userInitialDropdownMenu a:hover {
  background-color: #3d3d3d;
}

.loginButton {
  background-color: rgba(126, 64, 201, 0.6);
  color: #fff;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease,
    box-shadow 0.3s ease;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.loginButton:hover {
  background-color: rgba(126, 64, 201, 0.8);
  border-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.horizontalLine {
  width: 100%;
  height: 0.5px;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 60px;
  left: 0;
}

.pricingButtonWrapper {
  margin-right: 20px; /* Added margin to create space between pricing and account button */
  display: flex;
  align-items: center;
}

.creditsDisplay {
  display: flex;
  align-items: center;
  margin-right: 8px; /* Reduced margin to bring it closer to the pricing button */
  padding: 0 12px;
  font-size: 14px;
  color: #ffffff;
  height: 36px;
}

.creditsDisplay i {
  margin-left: 5px;
  margin-right: 3px;
  color: #ffd700;
  margin-top: 3px;
}

.creditsLabel {
  margin-right: 2px;
  font-weight: 500;
}

.creditsNumber {
  font-weight: 600;
}

.rightSection {
  display: flex;
  align-items: center;
}

.glassContainer {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  padding: 5px 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease; /* Add this line for smooth transition */
}

.glassContainerSearch {
  background: rgba(255, 255, 255, 0.031);
  backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.015);
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 1.75rem;
  height: 1.75rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.hamburger span {
  width: 1.75rem;
  height: 0.2rem;
  background: #fff;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.mobileMenu {
  display: none;
}

.zoomedInGlassContainer {
  background: rgba(0, 0, 0, 0.3);
}

.zoomedInGlassContainer .creditsDisplay i {
  color: rgba(255, 215, 0, 0.9);
  transition: color 0.3s ease;
}

@media screen and (max-width: 768px) {
  .topBar {
    background-color: transparent;
    backdrop-filter: none;
    padding: 0px;
    height: auto;
    width: 100vw;
  }

  .topBarNav {
    width: 100%;
  }

  .glassContainer {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    border-radius: 0px;
    width: 100%;
    justify-content: space-between;
  }

  .pricingButtonWrapper,
  .headerButton {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .mobileMenu {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    background-color: rgba(126, 64, 201, 0.95);
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    height: 100vh;
    padding: 2rem;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 1001;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
  }

  .mobileMenu.open {
    transform: translateX(0);
  }

  .mobileMenu a,
  .mobileMenu Link,
  .mobileMenu button {
    color: white;
    text-decoration: none;
    padding: 1rem 0;
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    transition: background-color 0.2s ease;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }

  .mobileMenu a:last-child,
  .mobileMenu Link:last-child,
  .mobileMenu button:last-child {
    border-bottom: none;
  }

  .mobileMenu a:hover,
  .mobileMenu Link:hover,
  .mobileMenu button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    line-height: 1;
    cursor: pointer;
    z-index: 1002;
    height: 50px;
    display: flex;
    align-items: end;
    justify-content: end;
    font-size: 1rem !important;
    padding: 5px;
  }

  .closeButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
}
