.alert {
  width: 75%;
  /* height: 25px; */
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed;
  top: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1em;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.success {
  background-color: #208437;
  color: #ffffff;
  border: 1px solid #208437;
}

.warning {
  background-color: #c60000;
  color: #ffffff;
  border: 1px solid #c60000;
}

.icon {
  margin-right: 10px;
  font-size: 1.2em;
}

.close-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.2em;
  cursor: pointer;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #d4d4d4;
}

.text {
  flex: 1;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .alert {
    width: 90%;
    min-height: 40px;
    border-radius: 8px;
    font-size: 0.9em;
    padding: 8px 15px;
    top: 14%;
  }

  .icon {
    font-size: 1em;
    margin-right: 8px;
  }

  .close-btn {
    font-size: 1em;
    margin-left: 8px;
  }

  .text {
    margin-left: 8px;
  }
}
