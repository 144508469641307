.tagContainer {
  position: absolute;
  right: 234px;
  bottom: 107px;
  transform: translateY(-50%);
  margin-right: 20px;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity var(--fade-in-duration) ease-in-out;
}

.tagContainer::after {
  content: '';
  position: absolute;
  right: -9.8px;
  top: 50%;
  transform: translateY(-50%);
  border-left: 10px solid rgba(255, 255, 255, 0.75);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.tagText {
  color: #333;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  font-weight: 500;
}
