/* Define the hue variables at the root level */
:root {
  --hue1: 0; /* Red */
  --hue2: 60; /* Yellow */
  --hue3: 120; /* Green */
  --hue4: 180; /* Cyan */
}

.tmAssistantContainer {
  position: relative;
  z-index: 90;
  transition: opacity var(--fade-in-duration) ease-in-out;
  opacity: 0;
}

.tmAssistantContainer.visible {
  opacity: 1;
}

.tmAssistantContainer.hidden {
  opacity: 0;
}

.dictationButton {
  position: relative;
  width: 78.645px;
  height: 78.645px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  border: 1.4px solid rgba(255, 255, 255, 0.72);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  z-index: 2; /* Ensure button is above the outline */
}

/* Remove the ::before pseudo-element from .dictationButton */

.dictationButton:hover {
  background: rgba(255, 255, 255, 0.55);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.75);
}

/* New styles for the outer outline */
.outerOutline {
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border-radius: 50%;
  border: 1.2px solid rgba(255, 255, 255, 0.3);
  z-index: 1;
  transition:
    transform 0.15s ease,
    border-color 0.15s ease; /* Reduced transition duration for more responsiveness */
}

.listeningOutline {
  background: rgba(255, 255, 255, 0.15);
  border: 1.65px solid rgba(223, 18, 18, 0.75) !important;
  transform: scale(1.5); /* Increased scale for more noticeable change */
}

.dictationButton i {
  font-size: 31.458px;
  color: #fff;
}

.dictationButton:hover {
  background: rgba(255, 255, 255, 0.55);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.75);
}

.dictationButton:not(.listening)::after {
  content: none;
}

/* Remove the conflicting ::before styles */
.listening,
.listening:hover {
  background: transparent;
  position: relative;
  overflow: visible;
}

/* Change the pulsing outline to a static red outline */
.dictationButton.listening {
  background: linear-gradient(
    45deg,
    hsl(350, 71%, 46%) 0%,
    /* Deep red */ hsl(330, 100%, 40%) 33%,
    /* Red-purple */ hsl(0, 57%, 46%) 67%,
    /* Red-orange */ hsl(230, 100%, 25%) 100% /* Deep blue */
  );
  background-size: 400% 400%;
  animation: gradientShift 10s ease infinite;
}

/* Remove the pulse keyframes as they're no longer needed */
@keyframes pulse {
  /* You can remove this entire keyframes block */
}

.listening i,
.listening:hover i {
  position: relative;
  z-index: 1;
  color: white;
}

.listening i {
  animation: listening 1.5s infinite;
}

@keyframes listening {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .tmAssistantContainer {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }

  .dictationButton {
    width: 67.41px;
    height: 67.41px;
    display: none; /* Hide the dictation button on mobile till we fix the issue */
  }

  .dictationButton i {
    font-size: 26.964px;
  }

  .microphoneIcon {
    width: 26.964px;
    height: 26.964px;
  }
}

.microphoneIcon {
  width: 41.52456px;
  height: 41.52456px;
  transition: transform 0.3s ease;
}

.listeningIcon {
  animation: iconPulse 1.5s ease-in-out infinite;
}

@keyframes iconPulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/* New hover effect to brighten the outer outline */
.tmAssistantContainer:hover .outerOutline {
  border-color: rgba(255, 255, 255, 0.45); /* Brighten by 0.3 opacity */
}

.permissionMessage {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 10px;
  white-space: nowrap;
}

.processingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
}

.processingOverlay.visible {
  opacity: 1;
}
