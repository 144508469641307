.lengthSelectorContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.lengthLabel {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.minimalSelect {
  appearance: none;
  background-color: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  padding: 4px 24px 4px 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 12 12'%3E%3Cpath fill='%23ffffff' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 8px;
}

.minimalSelect:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.15);
}

.minimalSelect:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.minimalSelect option {
  background-color: rgba(0, 0, 20, 0.8);
  color: rgba(255, 255, 255, 0.9);
}

.minimalSelect option:disabled {
  color: rgba(255, 255, 255, 0.5);
}

.minimalSelect:global(.hover-hand) {
  cursor: pointer;
}
