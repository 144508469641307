.promptText {
  width: 200px;
}

@media screen and (max-width: 768px) {
  .cardImage {
    display: none;
  }

  .promptText {
    width: 100%;
  }
}
