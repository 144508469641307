.exampleBox {
  position: absolute;
  bottom: calc(100% + 25px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  visibility: visible;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(196, 196, 196, 0.9);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  color: #000;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  white-space: normal;
  z-index: 1001;
  animation: fadeIn 0.3s forwards;
  text-align: center;
  width: max-content;
  max-width: 300px;
}

.exampleBox::before {
  content: "Try:\00a0";
  color: rgba(133, 110, 110, 0.93);
  font-weight: 500;
  display: inline-block;
}

.thickerText::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(196, 196, 196, 0.5);
  margin: 8px 0;
}

.exampleBox::after {
  content: "• rounds to multiples of 25\A• points to a range\A• max 500 BC";
  white-space: pre;
  display: block;
  color: rgba(99, 90, 90, 0.98);
  font-weight: 500;
  margin-top: 5px;
  text-align: left;
  font-style: italic;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    visibility: visible;
  }
}

.thickerText {
  text-shadow: 0.7px 0.7px 0 rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  .exampleBox {
    display: none;
    pointer-events: none;
  }
}
