.globe-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  overflow: visible;
}

@media (max-width: 640px) {
  .globe-container {
    transform: scale(0.65);
    transform-origin: center center;
    min-height: 260px;
    width: 154%; /* Compensate for scale (100/0.65 ≈ 154) */
    margin-left: -27%; /* (154% - 100%) / 2 = 27% */
  }
}

.globe-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.globe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.globe-popup {
  position: absolute;
  display: none;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none;
  z-index: 1000;
}
