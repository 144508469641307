.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin-top: 20px;
}

.icon-button svg {
  width: 50px;
  height: 50px;
  fill: white;
  transition: transform 0.2s ease-in-out;
}

.icon-button:hover svg {
  transform: scale(1.1);
}

.fade-in {
  animation: localFadeIn 1s ease-out;
}

@keyframes localFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.panning-image {
  width: 125%;
  max-width: 125%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  object-fit: cover;
  /* animation: pan 13s linear infinite; */
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border-radius: 24px;
}

.panning-image.active {
  display: block;
}

.panning-image.paused {
  animation-play-state: paused !important;
}

.video-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.glossy-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  backdrop-filter: blur(10px);
  z-index: 51;
}

.back-button {
  color: rgb(126, 79, 184);
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 20px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  text-align: center;
  margin-top: 10px;
  position: relative;
  z-index: 51;
  opacity: 1;
  transition: all 0.3s ease;
}

.back-button.dimmed {
  opacity: 0.8;
}

.back-button:hover {
  background-color: rgba(126, 79, 184, 0.1);
  color: rgb(255, 255, 255);
}

.back-button:active {
  background-color: rgba(126, 79, 184, 0.2);
  color: rgb(255, 255, 255);
}

.back-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(126, 79, 184, 0.5);
}

.back-button svg {
  margin-right: 8px;
  display: inline;
  align-items: center;
  vertical-align: top;
}

.back-button svg path {
  transition: stroke 0.3s ease;
  margin-right: 8px;
}

.back-button:hover svg path {
  stroke: rgb(255, 255, 255);
}

.continue-container {
  width: 100%;
  padding-top: 20px;
  /* display: none; */
}

.continue-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.continue-input {
  background-color: #2e2e2e;
  border: none;
  outline: none;
  color: #e6e6e6;
  padding: 10px;
  border-radius: 30px;
  width: 100%;
  height: 45px;
  padding-right: 55px;
  padding-left: 20px;
  font-size: 16px;
}

.submit-continue {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #4a148c;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 0px;
  border-radius: 30px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  /* line-height: 40px; */
}

.submit-continue:hover {
  background-color: #6a1b9a;
}

.submit-continue:active {
  background-color: #3f0e62;
  /* transform: scale(0.95); */
}

.continue-suggestions-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.suggestion-chip {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: 2px solid #4a148c; /* Tovera brand purple */
  color: #ffffff; /* Matching text color */
  padding: 6px 12px;
  border-radius: 16px; /* Rounded corners for chip-like appearance */
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.suggestion-chip:hover {
  background-color: #6a1b9a;
}

.suggestion-chip:active {
  background-color: #3f0e62;
  transform: scale(0.95);
}

.controls-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

#controls-container-inner {
  display: none;
  z-index: 10;
  cursor: pointer;
  padding: 10px 20px;
}
#image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  width: 75vh;
  height: 75vh;
  max-height: 100vh;
  overflow: hidden;
  border: none;
  position: relative;
  object-fit: cover;
  z-index: 51;
  transition: height 0.5s ease-in-out;
  border-radius: 27px;
}

#image-container:hover #controls-container-inner {
  display: block;
}

#progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px; /* Thin progress bar */
  background: radial-gradient(
    circle,
    rgba(95, 98, 255, 1) 15%,
    rgba(118, 96, 255, 1) 45%,
    rgba(131, 97, 255, 1) 80%
  );
  width: 0%; /* Start with 0 width */
  z-index: 50;
  transition: width 0.5s linear; /* Smooth transition for width changes */
  display: none; /* Hide the progress bar initially */
}

#image-container:hover #progress-bar {
  display: block; /* Show the progress bar on hover */
}

#play-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

#pause-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

#play-button,
#pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  cursor: default; /* Change cursor to default */
}

#continue-button,
#new-button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

#continue-input-container {
  display: flex;
  justify-content: center; /* Center the input and button horizontally */
  gap: 10px; /* Space between input field and button */
}

#continue-input {
  padding: 5px;
  width: 50%; /* Adjust width as necessary */
}

#submit-continue {
  padding: 5px 15px;
}

#subtitle-toggle {
  vertical-align: middle;
}

#subtitle-toggle-label {
  vertical-align: middle;
}

#new-continue-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

#voiceover-audio {
  display: none;
}

#background-music {
  display: none;
}

#story-form textarea,
#story-form input[type="text"] {
  width: 100%;
  height: 24.5vh;
  padding: 10px;
  text-align: left;
  font-size: 16px;
  border-radius: 10px;
  background-color: #2e2e2e;
  border: 1.1px solid #4a4a4a;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
}

#story-form textarea:focus,
#story-form input[type="text"]:focus {
  outline: none;
  border-color: #8e6bc2;
  box-shadow: 0 0 0 2px rgba(161, 121, 222, 0.2);
}

#storyPrompt {
  flex-grow: 1;
  padding: 10px 40px 40px 40px;
  box-sizing: border-box;
  margin-bottom: 0;
  background-color: #2e2e2e;
  color: #e6e6e6;
  position: relative;
}

#additional-info {
  height: 8vh !important; /* Overrides other styles regardless of specificity */ /* Height is 5% of the viewport height, which is roughly 1/4 of 20vh */
  background-color: #2e2e2e; /* Balanced background color */
  color: #e6e6e6; /* Balanced text color */
}

#subtitle-container {
  z-index: 99;
  position: absolute;
  bottom: 27.3%;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  max-width: 75vh;
  text-align: center;
  color: rgb(221, 217, 217);
  font-size: 1.5em;
  font-family: "Trebuchet MS", sans-serif;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
  white-space: normal;
  word-wrap: break-word;
  opacity: 1 !important; /* Force opacity to always be 1 */
  transition: none !important; /* Disable any transitions */
  animation: none !important; /* Disable any animations */
  border-radius: 8px;
}

@media (min-width: 768px) {
  #story-form textarea {
    font-size: 23px; /* Larger font size for wider screens */
  }
}

.dimming-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: none;
  transition: opacity 0.5s ease-in-out; /* Default transition */
}

.dimming-overlay.play {
  transition-duration: 0.4s;
}

.dimming-overlay.pause {
  transition-duration: 0.35s;
}

form {
  width: 100%;
}

/* Mobile styles for HomePage */
@media screen and (max-width: 768px) {
  .initial-ui-container {
    max-width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
  }

  #story-prompt {
    width: 100%;
    box-sizing: border-box;
    resize: none;
    min-height: 120px;
    flex-grow: 1;
    padding: 10px;
    padding-right: 40px;
    margin-bottom: 10px;
    padding-left: 70px;
  }

  #story-form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .controls-container {
    padding: 5px;
  }

  .video-content {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 60px;
  }

  #image-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
  }

  .panning-image {
    height: 100%;
    object-fit: cover;
  }

  #subtitle-container {
    font-size: 14px;
    padding: 10px;
    bottom: calc(25% + 25px); /* Raised by 25px for mobile */
    width: 80%;
  }

  .back-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .continue-suggestions-container {
    flex-direction: column;
    gap: 0px;
  }

  .suggestion-chip {
    text-align: center;
    justify-content: center;
  }

  .back-button {
    margin-top: 0px;
  }

  #play-button,
  #pause-button {
    transform: translate(-50%, -50%) scale(0.8);
  }

  #progress-bar {
    height: 3px;
  }

  #new-continue-buttons {
    flex-direction: column;
    gap: 10px;
  }

  #continue-button,
  #new-button,
  #download-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

  #continue-input-container {
    flex-direction: column;
    gap: 10px;
  }

  #continue-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

  #submit-continue {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

  #addon-art-style-button,
  #replace-art-style-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
  }

  #controls-container-inner {
    display: none !important;
  }

  #pause-button {
    display: none !important;
  }

  /* Only show play button when video is not playing */
  #controls-container-inner:has(#play-button) {
    display: block !important;
  }
}

.watermark {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 52;
  opacity: 0.4;
  pointer-events: none;
  animation: fadeIn 0.5s ease-in-out;
}

.watermark img {
  width: 120px;
  height: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 1000;
  background: radial-gradient(
    circle at center,
    transparent 0%,
    rgba(0, 0, 0, 0.3) 70%,
    rgba(0, 0, 0, 0.5) 100%
  );
  opacity: 0;
  transition: opacity 0.85s ease-in-out;
  pointer-events: none;
}

.loader-container.loading {
  opacity: 1;
  pointer-events: auto;
}