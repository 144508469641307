.dropdown-container {
  position: relative;
  width: calc(33.33% - 4px);
  margin: 0;
}

.dropdown-button {
  background: rgba(255, 255, 255, 0.085);
  backdrop-filter: blur(10px);
  border: 1.5px solid rgba(255, 255, 255, 0.45);
  border-radius: 12px;
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-button:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-label {
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 8px;
  padding-right: 8px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
}

.button-text {
  flex-grow: 1;
  text-align: left;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-arrow {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.dropdown-button.open .dropdown-arrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.label {
  position: absolute;
  top: -8px;
  left: 10px;
  background-color: #141414;
  padding: 0 5px;
  color: #a179de;
  font-size: 11px;
  font-weight: 500;
  z-index: 1;
  border-radius: 8px;
}

.dropdown-icon {
  margin-right: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  transform: translateY(1px);
}

.dropdown-options {
  background: rgba(255, 255, 255, 0.085);
  backdrop-filter: blur(10px);
  border: 1.5px solid rgba(255, 255, 255, 0.45);
  position: absolute;
  border-radius: 8px;
  width: 100%;
  max-width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  padding: 0;
  margin: 0;
  font-size: 13px;
  top: 47.5px;
  box-shadow: 
    0 8px 16px rgba(0, 0, 0, 0.15),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.dropdown-options li {
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.9);
  padding: 10.2px 12.75px;
  cursor: pointer;
  list-style-type: none;
  text-align: left;
  transition: all 0.15s ease;
  box-sizing: border-box;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  height: auto;
}

.dropdown-options li:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: #ffffff;
}

.dropdown-options li:last-child {
  border-bottom: none;
}

.dropdown-options li:first-child,
.dropdown-options li:first-child:hover {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown-options li:last-child,
.dropdown-options li:last-child:hover {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dropdown-options::-webkit-scrollbar {
  width: 8px;
}

.dropdown-options::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  border: 2px solid transparent;
}

.dropdown-options::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.gallery {
  background: rgba(255, 255, 255, 0.085);
  backdrop-filter: blur(10px);
  border: 1.5px solid rgba(255, 255, 255, 0.45);
  padding: 8px;
  gap: 0;
  width: 512px;
  box-sizing: border-box;
  top: 37.5px;
  display: flex;
  justify-content: space-between;
  z-index: 1100;
  box-shadow: 
    0 8px 16px rgba(0, 0, 0, 0.15),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.gallery li {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  border: none;
  position: relative;
  box-sizing: border-box;
}

.gallery li:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 10%;
  height: 80%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.065);
}

.gallery li:first-child,
.gallery li:last-child {
  border: none;
}

.gallery-item-label {
  display: block;
  margin-bottom: 5px;
  color: white;
  font-size: 15px;
  text-align: left;
  width: 100%;
  padding-left: 10px;
}

.gallery-image {
  width: 85%;
  height: auto;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: 0.2s;
}

.image-placeholder {
  width: 85%;
  height: 100px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.gallery .gallery-image {
  width: 85%;
  height: auto;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition:
    outline 0.1s ease-in-out,
    transform 0.1s ease-in-out;
}

.gallery li:hover .gallery-image {
  outline: 2px solid rgb(74, 5, 139);
  transform: scale(1.01);
}

li.recommended {
  font-weight: bold;
}

.fadeIn {
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInWithTranslate {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(10px);
  }
}

.preset-icon {
  margin-right: 8px;
  font-size: 16px;
  flex-shrink: 0;
  align-self: center; /* center the icon vertically */
}

.dropdown-options li span {
  display: flex;
  align-items: center; /* Revert to original alignment */
}

.preset-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
}

.dropdown-options li span::first-line {
  text-indent: 0;
}

.dropdown-options li span::after {
  content: "";
  display: inline-block;
  width: 3.5px;
}

.button-text {
  flex-grow: 1;
  text-align: left;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disabled-option {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.disabled-option:hover {
  background-color: transparent !important;
  color: #dab6fc !important;
}

/* Specific styles for mode type dropdown */
.dropdown-options[id="mode-options"] li {
  height: auto;
  min-height: 48px; /* Minimum height for consistency */
  padding: 8px 12.75px;
  align-items: center;
  display: flex;
}

.dropdown-options[id="mode-options"] li span {
  display: flex;
  align-items: center;
}

.dropdown-options[id="mode-options"] .preset-icon {
  margin-right: 8px;
  font-size: 16px;
  flex-shrink: 0;
  align-self: center;
}

.dropdown-options[id="mode-options"] .preset-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
}

.glow-effect {
  animation: glowAnimation 0.5s ease-out;
}

@keyframes glowAnimation {
  0% {
    box-shadow: 0 0 4px rgba(126, 79, 184, 0.5);
  }
  50% {
    box-shadow: 0 0 12px rgba(126, 79, 184, 0.8);
  }
  100% {
    box-shadow: 0 0 4px rgba(126, 79, 184, 0.5);
  }
}

.gallery {
  width: 512px;
  max-width: 512px;
  overflow-y: visible;
  overflow-x: visible;
  max-height: none;
  transform: translateY(10px);
  animation: fadeInWithTranslate 0.2s ease-out;
}

/* Add a new class for the container of all dropdowns */
.dropdowns-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 700px; /* Match the max-width of the parent container */
  margin: 0 auto;
  gap: 6px;
}

@media screen and (max-width: 768px) {
  .dropdown-container {
    width: 100%;
    margin-bottom: 30px;
    padding: 0;
    box-sizing: border-box;
  }

  .dropdown-button {
    max-width: 100%;
  }

  .gallery {
    max-width: 100vw;
    width: calc(100vw - 20px);
    box-sizing: border-box;
    left: 0;
    transform: translateX(-50%);
  }
}
