.sidebar-container {
  --sidebar-steel-light: rgba(211, 216, 224, 0.075);
  --sidebar-steel-medium: rgba(169, 178, 191, 0.075);
  --sidebar-steel-dark: rgba(125, 138, 158, 0.075);
  --sidebar-steel-darker: rgba(92, 107, 127, 0.075);

  background: 
    linear-gradient(135deg, 
      var(--sidebar-steel-light) 0%, 
      var(--sidebar-steel-medium) 33%, 
      var(--sidebar-steel-dark) 67%, 
      var(--sidebar-steel-darker) 100%
    );
  /* border-radius: 0.5rem; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  /* border: 1px solid rgba(255, 255, 255, 0.05); */
  overflow: hidden;
}