/* src/components/DictationButton/ToggleDictation.css */
.mic-icon {
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 24px;
  color: #959595; /* Light modern gray color */
}

.mic-icon:hover {
  color: #585858; /* Darker gray on hover */
}

.mic-icon.active {
  color: #ff6347;
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.mic-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dictationButton {
  background-color: rgba(148, 147, 147, 0.15);
  border-radius: 50%;
  padding: 6.8px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dictationButton:hover,
.dictationButton.active {
  background-color: rgba(148, 147, 147, 0.35);
}

.icon {
  width: 20px;
  height: 20px;
  color: rgba(197, 197, 197, 0.65);
  transition: color 0.3s ease;
}

.dictationButton:hover .icon {
  color: rgba(217, 217, 217, 0.65);
}

.listening .icon {
  color: rgba(239, 68, 68, 0.65);
  animation: pulse 1.5s ease-in-out infinite;
}

.listening.dictationButton {
  background-color: rgba(78, 78, 78, 0.65);
}
