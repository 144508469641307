.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18.5px;
}

.dot {
  width: 6px;
  height: 6px;
  margin: 0 2px;
  background-color: #000000;
  border-radius: 50%;
  animation: dot-blink 1.4s infinite both;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
