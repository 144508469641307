.yearSelector {
  --current-lightest: rgba(255, 255, 255, 0.135);
  --current-lighter: rgba(255, 255, 255, 0.153);
  --current-light: rgba(255, 255, 255, 0.171);
  --current-medium: rgba(255, 255, 255, 0.189);
  --current-dark: rgba(255, 255, 255, 0.189);
  --current-darker: rgba(255, 255, 255, 0.198);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-radius: 30px;
  background: linear-gradient(
    135deg,
    var(--current-lightest) 0%,
    var(--current-lighter) 20%,
    var(--current-light) 40%,
    var(--current-medium) 60%,
    var(--current-dark) 80%,
    var(--current-darker) 100%
  );
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  background-image: linear-gradient(135deg,
    hsla(216, 82%, 65%, 0.35),
    hsla(340, 100%, 77%, 0.22)
  );
  background-origin: border-box;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.12),
              0 0 12px rgba(255, 255, 255, 0.12),
              inset 0 0 15px rgba(255, 255, 255, 0.15); /* Increased opacity and spread */
  transition: background 0.3s ease;
}

.yearSelector.adaptive {
  --color-1: hsla(220, 100%, 77%, 0.25);
  --color-2: hsla(260, 100%, 82%, 0.28);
  --color-3: hsla(340, 100%, 77%, 0.25);
  
  background: linear-gradient(135deg,
    var(--color-1) 0%,
    var(--color-2) 50%,
    var(--color-3) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.27);
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.14),
              inset 0 0 30px rgba(255, 255, 255, 0.18),
              inset 0 0 20px rgba(180, 160, 255, 0.25),
              0 0 15px rgba(180, 160, 255, 0.12);
  backdrop-filter: blur(15px);
  position: relative;
  overflow: hidden;
}

/* Replace the existing ::before with this new one */
.yearSelector.adaptive::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg,
    hsla(220, 100%, 77%, 0) 0%,
    hsla(220, 100%, 77%, 0.07) 15%,
    hsla(260, 100%, 82%, 0.07) 30%,
    hsla(340, 100%, 77%, 0.07) 45%,
    hsla(260, 100%, 82%, 0.07) 60%,
    hsla(220, 100%, 77%, 0.07) 75%,
    hsla(220, 100%, 77%, 0) 100%
  );
  pointer-events: none;
}

.yearDisplay {
  margin: 0 12px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.5px;
  font-size: 24px;
}

.arrow {
  cursor: pointer;
  user-select: none;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transition: all 0.2s ease;
}

.arrow.adaptive {
  background: rgba(180, 160, 255, 0.15);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.arrow:hover {
  background: rgba(255, 255, 255, 0.2);
}

.arrow.adaptive:hover {
  background: rgba(140, 180, 255, 0.25);
  box-shadow: 0 0 15px rgba(180, 160, 255, 0.25);
}

/* Note: Font switching is currently not active */
/* You can remove or comment out these font-specific classes if they're no longer needed */
/*
.font1900 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  margin-bottom: -2.5px;
}
.font1600 {
  font-family: "IM Fell English", serif;
  font-size: 38px;
}
.font1050 {
  font-family: "Goudy Bookletter 1911", serif;
  font-size: 38px;
}
.font700 {
  font-family: "Grenze Gotisch", cursive;
  font-size: 32px;
}
.font0 {
  font-family: "Cinzel", serif;
  font-size: 40px;
}
.font1000BC {
  font-family: "Papyrus", fantasy;
  font-size: 25px;
}
.font3500BC {
  font-family: "Rock Salt", cursive;
  font-size: 16.5px;
}
*/

/* New class for BC years */
.yearBC {
  font-size: 82%; /* Reduce font size by 12% for BC years */
}

/* New class for years 1000 BC and beyond */
.year1000BC {
  font-size: 62%; /* Reduce font size by 30% for years 1000 BC and beyond */
}

@media (max-width: 768px) {
  .yearSelector {
    width: 298px;
    padding: 8px 13px;
    z-index: 1000;
    margin-top: 12px;
  }

  .yearDisplay {
    font-size: 19px; /* Reduced from 24px by 20% */
    margin: 0 10px; /* Reduced from 0 12px */
  }

  .arrow {
    width: 29px; /* Reduced from 36px by 20% */
    height: 29px;
    font-size: 19px; /* Reduced from 24px by 20% */
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 0.3s ease;
}

/* New Disabled Class */
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes borderPulse {
  0% {
    border-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.12),
                0 0 12px rgba(255, 255, 255, 0.12),
                inset 0 0 15px rgba(255, 255, 255, 0.15);
  }
  50% {
    border-color: rgba(255, 255, 255, 0.35);
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.12),
                0 0 12px rgba(255, 255, 255, 0.12),
                inset 0 0 22px rgba(255, 255, 255, 0.25); /* Increased spread and opacity */
  }
  100% {
    border-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.12),
                0 0 12px rgba(255, 255, 255, 0.12),
                inset 0 0 15px rgba(255, 255, 255, 0.15);
  }
}

.yearSelector.isChanging {
  animation: borderPulse 0.8s ease-in-out;
}

.yearSelector.isChanging.holding {
  animation: borderPulse 0.8s ease-in-out infinite;
}

/* For adaptive style, we need a separate animation */
@keyframes adaptiveBorderPulse {
  0% {
    border-color: rgba(255, 255, 255, 0.27);
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.14),
                inset 0 0 30px rgba(255, 255, 255, 0.18),
                inset 0 0 20px rgba(180, 160, 255, 0.25),
                0 0 15px rgba(180, 160, 255, 0.12);
  }
  50% {
    border-color: rgba(255, 255, 255, 0.35);
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.14),
                inset 0 0 38px rgba(255, 255, 255, 0.28),
                inset 0 0 28px rgba(180, 160, 255, 0.35),
                0 0 15px rgba(180, 160, 255, 0.12);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.27);
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.14),
                inset 0 0 30px rgba(255, 255, 255, 0.18),
                inset 0 0 20px rgba(180, 160, 255, 0.25),
                0 0 15px rgba(180, 160, 255, 0.12);
  }
}

.yearSelector.adaptive.isChanging {
  animation: adaptiveBorderPulse 0.8s ease-in-out;
}

.yearSelector.adaptive.isChanging.holding {
  animation: adaptiveBorderPulse 0.8s ease-in-out infinite;
}

/* Add new class for rapid clicking animations */
@keyframes subtleBorderPulse {
  0%, 100% {
    border-color: rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.12);
  }
  50% {
    border-color: rgba(255, 255, 255, 0.3);
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.15);
  }
}

@keyframes subtleAdaptiveBorderPulse {
  0%, 100% {
    border-color: rgba(255, 255, 255, 0.27);
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.15);
  }
  50% {
    border-color: rgba(255, 255, 255, 0.32);
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.18);
  }
}

/* Update the rapid clicking styles to maintain resting state */
.yearSelector.isChanging.rapidClicking {
  animation: none !important;
  /* Use the same box-shadow as the non-changing state */
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.12),
              0 0 12px rgba(255, 255, 255, 0.12),
              inset 0 0 15px rgba(255, 255, 255, 0.15) !important;
}

.yearSelector.adaptive.isChanging.rapidClicking {
  animation: none !important;
  /* Use the same box-shadow as the non-changing adaptive state */
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.14),
              inset 0 0 30px rgba(255, 255, 255, 0.18),
              inset 0 0 20px rgba(180, 160, 255, 0.25),
              0 0 15px rgba(180, 160, 255, 0.12) !important;
}

/* Remove the holding animations during rapid clicking */
.yearSelector.isChanging.holding.rapidClicking,
.yearSelector.adaptive.isChanging.holding.rapidClicking {
  animation: none !important;
}

