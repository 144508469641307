.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1a1a1a;
}

.card {
  background: #1e1e1e;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #ffffff;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #4caf50;
}

.message {
  font-size: 18px;
  margin-bottom: 30px;
}

.button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  background-color: #45a049;
}
