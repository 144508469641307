.login-modal {
  font-family: "Montserrat", sans-serif;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  display: flex;
  width: 770px;
  height: 590px;
  background-color: #1a1a1a;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(106, 27, 154, 0.25);
  animation: modalFadeIn 0.3s ease-out;
}

.image-container {
  flex: 1;
  overflow: hidden;
  min-width: 50%;
}

.login-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-container {
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  max-width: 50%;
}

.btn-close {
  position: absolute;
  top: 11.5px;
  right: 11.5px;
  background: rgba(26, 26, 26, 0.25);
  border: none;
  color: rgba(255, 255, 255, 0.35);
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.btn-close:hover {
  background-color: rgba(26, 26, 26, 0.8);
  color: rgba(255, 255, 255, 0.8);
}

.modal-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  background: linear-gradient(90deg, #7d3eb1, #9d6ad6, #7d3eb1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 200% auto;
  animation: gradientFlow 11s linear infinite;
}

@keyframes gradientFlow {
  0% {
    background-position: 0% center;
  }
  100% {
    background-position: 200% center;
  }
}

/* Custom Google Sign-In Button */
.google-sign-in {
  width: 100%;
  height: 50px;
  background-color: #26272c;
  border: 1px solid #444;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-bottom: 20px;
}

.google-sign-in:hover {
  background-color: #32333a;
  border-color: #555;
}

.google-sign-in svg {
  margin-right: 10px;
}

/* Remove FirebaseUI specific styles */
#firebaseui-auth-container {
  display: none;
}

/* Divider styles */
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #444;
}

.divider-text {
  padding: 0 10px;
  color: #888;
  font-size: 12px;
  background-color: #1a1a1a;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

/* Placeholder button styles */
.placeholder-button {
  width: 100%;
  height: 50px;
  background-color: #26272c;
  border: 1px solid #444;
  border-radius: 8px;
  color: #888;
  font-size: 14px;
  margin-top: 10px;
  cursor: not-allowed;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.placeholder-button:hover {
  background-color: #32333a;
  border-color: #555;
}

/* Placeholder for future login options */
.future-login-option {
  width: 100%;
  height: 50px;
  background-color: #2a2a2a;
  border-radius: 25px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-style: italic;
}

.terms-text {
  font-size: 12px;
  color: #888;
  text-align: center;
  margin-top: 20px;
  opacity: 1 !important;
  animation: none !important;
  transition: none !important;
}

.terms-link {
  color: #a179de;
  text-decoration: none;
  opacity: 1 !important;
  animation: none !important;
  transition: none !important;
}

.terms-link:hover {
  text-decoration: underline;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .login-modal {
    padding: 10px;
    box-sizing: border-box;
  }

  .modal-content {
    height: auto;
    flex-direction: column;
  }

  .image-container {
    display: none;
  }

  .form-container {
    padding: 20px;
    max-width: 100%;
  }

  .modal-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .google-sign-in {
    height: 45px;
    font-size: 14px;
  }

  .placeholder-button {
    height: 45px;
    font-size: 13px;
  }

  .future-login-option {
    height: 45px;
    margin-top: 15px;
  }

  .terms-text {
    font-size: 11px;
    margin-top: 15px;
  }
}
