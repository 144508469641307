.pricing-modal {
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  color: #fff;
  position: fixed;
  z-index: 1501;
  left: 0;
  top: 0;
  width: 100vw;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url('../../images/pricingbg.svg') center/cover no-repeat;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #e0e0e0;
  font-size: 1rem;
  font-weight: bold;
  width: 1200px;
  bottom: calc(8% + 42.5px);
  top: calc(9% + 42.5px);
  left: calc(50% - 600px);
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.modal-header {
  position: absolute;
  z-index: 2;
  background-color: transparent;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none;
  padding-bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.05);
  z-index: 1000;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-title {
  font-size: 1.5rem; /* Increase font size */
  line-height: 1.5rem;
  font-weight: 600; /* Make it slightly bolder */
  margin: 0;
  margin-top: 0; /* Remove negative top margin */
  padding: 0 20px; /* Add horizontal padding */
}

.btn-close {
  border: none;
  background-color: transparent;
  font-size: 1.1rem; /* Reduced from 1.25rem */
  font-weight: 600; /* Added to make it slightly thicker */
  cursor: pointer;
  color: #ffffff;
  opacity: 0.5;
  padding: 5px;
  margin-right: 0; /* Remove right margin */
  line-height: 1; /* Added to ensure proper vertical alignment */
}

.btn-close:hover {
  opacity: 0.8;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 20px;
  padding-top: 0px;
  margin-top: 56px;
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(100vh - 205px); /* Reduce max-height by 35px */
  scrollbar-gutter: stable both-edges;
}

/* Styling the scrollbar */
.modal-body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.modal-body::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3); /* Changed from #333 */
  border-radius: 4px; /* Rounded corners on the scroll thumb */
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5); /* Changed from #555 */
}

.pricing-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  background: transparent;
  color: #ffffff;
  height: 100%;
  margin-top: 30px;
}

.priced-options {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  margin-top: 5px;
}

.card {
  flex: 1;
  padding: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 13.5px;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
}

.card.starter {
  border: 1px solid rgba(144, 238, 144, 0.5);
  background: linear-gradient(145deg, rgba(144, 238, 144, 0.2), rgba(144, 238, 144, 0.3)); /* Increased opacity */
  border: 1.2px solid rgba(144, 238, 144, 0.3);
}

.card.pro {
  border: 1px solid rgba(74, 144, 226, 0.4);
  background: linear-gradient(135deg,
    rgba(100, 181, 246, 0.18) 0%,
    rgba(74, 144, 226, 0.22) 35%,
    rgba(41, 128, 185, 0.25) 65%,
    rgba(25, 118, 210, 0.28) 100%
  );
  border: 1.7px solid rgba(74, 144, 226, 0.3);
  box-shadow: inset 0 0 20px rgba(74, 144, 226, 0.2),
              0 0 25px rgba(74, 144, 226, 0.25),
              0 0 35px rgba(74, 144, 226, 0.12);
}

.card.pro::before {
  content: "Recommended";
  position: absolute;
  top: 0.1px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(161, 121, 222, 0.6);
  color: #fff;
  padding: 4px 12px;
  border-radius: 0 0 4px 4px;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15),
              inset 0 0 8px rgba(255, 255, 255, 0.1);
}

.card.premium {
  border: 1px solid rgba(176, 196, 222, 0.4);
  background: linear-gradient(135deg,
    rgba(220, 230, 242, 0.18) 0%,
    rgba(176, 196, 222, 0.22) 33%,
    rgba(147, 176, 212, 0.25) 67%,
    rgba(123, 159, 204, 0.28) 100%
  );
  border: 1.7px solid rgba(176, 196, 222, 0.3);
  box-shadow: inset 0 0 15px rgba(176, 196, 222, 0.15),
              0 0 15px rgba(176, 196, 222, 0.15);
}

.card h2 {
  margin-top: 8px;
  margin-bottom: -5px;
  font-size: 1.6rem;
}

.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.price {
  font-size: 42px;
  margin-top: 10px; /* Added to push the price down */
  margin-bottom: 5px; /* Added to maintain some space below */
}

.freq,
.billed {
  font-size: 16px;
  color: #b3b1b1;
}

.freq {
  margin-left: -3px;
  margin-right: 10px;
}

.subscribe-btn {
  display: block;
  padding: 10px;
  border-radius: 5px;
  /* Slightly brighter base state */
  background: rgba(255, 255, 255, 0.18);
  backdrop-filter: blur(10px);
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s ease;
  /* Slightly more visible border */
  border: 1.5px solid rgba(255, 255, 255, 0.25);
  /* Subtle glow effect */
  box-shadow: 0 2px 8px rgba(255, 255, 255, 0.1),
              inset 0 1px 1px rgba(255, 255, 255, 0.1);
}

.subscribe-btn:hover {
  background: rgba(255, 255, 255, 0.28);
  border: 1.5px solid rgba(255, 255, 255, 0.35);
  /* Enhanced glow on hover */
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.15),
              inset 0 1px 1px rgba(255, 255, 255, 0.2);
}

.subscribe-btn:active {
  transform: translateY(0px);
  box-shadow: 0 2px 8px rgba(255, 255, 255, 0.1);
}

.active-subscription {
  margin: 0;
  /* width: 100px; */
  padding: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  text-decoration: none;
  font-weight: bold;
  outline: 2px solid #ffdf8e;
  text-align: center;
  /* margin-top: 10px; */
  pointer-events: none;
  cursor: default;
}

.features {
  text-align: left;
  list-style: none;
  padding: 0;
  margin-top: 18.5px; /* Reduced by 1.5px */
  margin-bottom: 21.5px; /* Increased by 1.5px */
  font-size: 16px;
}

.features li {
  margin-bottom: 10px;
  padding-left: 20px;
  background: url("../../images/checkmark.svg") no-repeat left 5px;
  background-size: 14px;
  color: #fff;
  line-height: 1.5;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 15px 0;
  opacity: 0.1;
}

.features li:not(:first-child) {
  margin-bottom: 16px;
}

/* .starter-card,
.pricing-card {
  text-align: left;
}

.starter-card h2,
.pricing-card h2,
.starter-card .price,
.pricing-card .price {
  text-align: left;
} */

.discount {
  font-size: 14.5px;
  color: #c9c7c7;
  margin-top: 0;
}

.credits {
  color: inherit;
}
.credits .highlight {
  color: inherit;
}
.credits .hours {
  color: #ffdf8e;
}
.credits .up-to {
  color: #fff;
}

#basic-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 13.5px;
  background-color: rgba(255, 255, 255, 0.1); 
  backdrop-filter: blur(10px);
  height: 100%;
  width: 100%;
}

#basic-card .divider {
  background-color: rgba(255, 255, 255, 0.3);
  height: 1px; 
  margin: 20px 0;
  width: 293px; 
  max-width: 100%; 
}

#basic-card .price-container {
  display: flex;
  align-items: baseline;
}

#basic-card .price {
  font-size: 42px;
  margin-bottom: 5px;
  padding-top: 2px;
}

#basic-card .freq {
  font-size: 16px;
  color: #b3b1b1;
  margin-left: 5px;
}

#basic-card .subscribe-btn,
#basic-card .active-subscription {
  width: calc(
    33.33% - 40px
  ); /* Match width of other cards, subtracting padding */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  margin-top: auto;
  align-self: flex-start; /* Align to the left */
}

#basic-card .active-subscription {
  background-color: transparent;
  color: white;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid #ffdf8e;
}

.free-option {
  margin-top: 15px;
  width: 100%; /* Span full width of the pop-up */
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-header,
.card-body,
.card-footer {
  padding: 1rem;
}

.card-header {
  background-color: transparent;
}

.card-body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.spacer {
  flex-grow: 1;
}

.card-footer {
  background-color: rgba(255, 255, 255, 0.03);
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  padding-top: 15px; /* Added to increase space between features and footer */
  padding-bottom: 15px; /* Added to balance the footer padding */
}

.subscribe-btn {
  display: block;
  width: 100%;
}

.features .more-features {
  color: #a0a09e;
  font-style: italic;
}

.features li:last-child {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .pricing-modal {
    padding: 10px;
    box-sizing: border-box;
  }

  .modal-content {
    width: 95%;
    max-width: none;
    height: auto;
    max-height: 90vh;
    left: 2.5%;
    top: 5%;
    bottom: 5%;
    overflow-y: auto;
    background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url('../../images/pricingbg.svg') center/cover no-repeat;
    backdrop-filter: none;
  }

  .modal-header {
    /* width: 100%;
    box-sizing: border-box;
    background-color: #000000;
    z-index: 1000;
    padding: 15px 20px;
    border-bottom: 1px solid #333; */
  }

  .modal-title {
    font-size: 1.2rem;
  }

  .btn-close {
    font-size: 1rem;
    padding: 5px;
  }

  .modal-body {
    padding: 10px;
    max-height: none;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  .pricing-container {
    gap: 10px;
    margin-top: 10px;
  }

  .priced-options {
    flex-direction: column;
    gap: 15px;
    margin-top: 0;
  }

  .card {
    width: 100%;
    margin-bottom: 15px;
  }

  .card h2 {
    font-size: 1.2rem;
  }

  .price {
    font-size: 32px;
  }

  .freq,
  .billed {
    font-size: 14px;
  }

  .subscribe-btn,
  .active-subscription {
    padding: 8px;
    font-size: 14px;
  }

  .features {
    font-size: 14px;
  }

  .features li {
    margin-bottom: 8px;
    padding-left: 18px;
    background-size: 12px;
  }

  #basic-card {
    padding: 15px;
  }

  #basic-card .price {
    font-size: 32px;
  }

  #basic-card .freq {
    font-size: 14px;
  }

  #basic-card .subscribe-btn,
  #basic-card .active-subscription {
    width: 100%;
    font-size: 14px;
  }
}

#basic-card .features {
  margin-bottom: 25px;
}

#basic-card .subscribe-btn,
#basic-card .active-subscription {
  margin-top: 15px;
}

