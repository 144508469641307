.countryDisplayContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 0 10px rgba(255, 255, 255, 0.1),
              inset 0 0 15px rgba(255, 255, 255, 0.15);
  transition: all 0.3s ease;
  position: relative;  /* Add this to make absolute positioning work */
}

.countryDisplayContainer:hover {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 
              0 0 15px rgba(255, 255, 255, 0.2),
              inset 0 0 12px rgba(255, 255, 255, 0.15);
}

.countryName {
  font-size: 24px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.5px;
}

.countryDisplayContainer.adaptive {
  background: rgba(86, 85, 85, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12),
              inset 0 0 15px rgba(255, 255, 255, 0.15),
              inset 0 0 25px rgba(255, 255, 255, 0.1);
}

.regionIndicator {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.57);
  font-weight: 400;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  padding: 2px 6px;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px 0 0 0;
  background: rgba(0, 0, 0, 0.0);
  margin-left: 0;
  transform-origin: bottom right;
  display: flex;
  align-items: center;
  gap: 4px;
}

.infoIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 11px;
  height: 11px;
  border: 1px solid rgba(255, 255, 255, 0.57);
  border-radius: 50%;
  font-size: 9px;
  cursor: help;
  position: relative;
  text-transform: lowercase; 
  font-family: serif;
  font-weight: bold;
}

/* Add this new rule */
.infoIcon::before {
  content: '';
  position: absolute;
  top: -8.5px;
  right: -8.5px;
  bottom: -8.5px;
  left: -8.5px;
  cursor: help;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.85);
  
  color: rgba(255, 255, 255, 0.9);
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 13px;
  width: 300px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.4;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.15);
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
  font-family: 'Inter', sans-serif;
  transform: none;
  z-index: 1000;
}

.infoIcon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Replace the existing long-name classes with these new ones */
.countryDisplayContainer[data-long-name="medium"] .regionIndicator {
  transform: scale(0.95);
}

.countryDisplayContainer[data-long-name="medium"] .regionIndicator > *:not(.tooltip) {
  transform: scale(0.95);
}

.countryDisplayContainer[data-long-name="long"] .regionIndicator {
  transform: scale(0.87);
}

.countryDisplayContainer[data-long-name="long"] .regionIndicator > *:not(.tooltip) {
  transform: scale(0.87);
}

.countryDisplayContainer[data-long-name="medium"] .regionIndicator > *:not(.tooltip):not(.infoIcon) {
  transform: scale(0.95);
}

.countryDisplayContainer[data-long-name="long"] .regionIndicator > *:not(.tooltip):not(.infoIcon) {
  transform: scale(0.87);
}

.countryDisplayContainer[data-long-name="medium"] .tooltip {
  font-size: 16px;
  width: 300px;
}

.countryDisplayContainer[data-long-name="long"] .tooltip {
  font-size: 16px;
  width: 300px;
}

@media (max-width: 768px) {
  .countryDisplayContainer {
    padding: 10px 16px; /* Reduced from 12px 20px */
    border-radius: 10px; /* Reduced from 12px */
  }

  .countryName {
    font-size: 19px; /* Reduced from 24px by 20% */
  }

  .regionIndicator {
    font-size: 9px;
    padding: 1px 4px;
    margin-left: 8px;
  }

  /* Add these new rules */
  .infoIcon {
    display: none;
  }

  .regionIndicator {
    gap: 0;
  }
}
