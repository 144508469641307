.uiverse {
  --duration: 11.5s;
  --easing: linear;
  --c-color-1: rgba(126, 167, 216, 0.6);
  --c-color-2: #4d4dc4;
  --c-color-3: #6c45c4;
  --c-color-4: rgba(143, 174, 218, 0.6);
  --c-shadow: rgba(126, 167, 216, 0.4);
  --c-shadow-inset-top: rgba(126, 167, 216, 0.8);
  --c-shadow-inset-bottom: rgba(202, 207, 218, 0.7);
  --c-radial-inner: #759ccd;
  --c-radial-outer: #98b0cc;
  --c-color: #ffffff;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  outline: none;
  position: relative;
  cursor: pointer;
  border: none;
  display: table;
  border-radius: 48px; /* Increased for larger button */
  padding: 0;
  margin: 0;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 18px; /* Increased font size */
  letter-spacing: 0.04em;
  line-height: 1.5;
  color: var(--c-color);
  background: radial-gradient(
    circle,
    var(--c-radial-inner),
    var(--c-radial-outer) 80%
  );
  box-shadow: 0 0 28px var(--c-shadow); /* Increased shadow size */
  width: 340px; /* Set fixed width */
  border: 1.4px solid rgba(255, 255, 255, 0.7);
  transition:
    filter 0.3s ease,
    box-shadow 0.3s ease;
}

.uiverse:before {
  content: "";
  pointer-events: none;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 48px; /* Match button border-radius */
  box-shadow:
    inset 0 6px 24px var(--c-shadow-inset-top),
    /* Increased shadow */ inset 0 -6px 8px var(--c-shadow-inset-bottom); /* Increased shadow */
}

.uiverse .wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  border-radius: 48px; /* Match button border-radius */
  min-width: 340px; /* Updated min-width */
  padding: 12px 0; /* Increased padding */
  position: relative; /* Ensure positioning context for circles */
  height: 50px; /* Increased height for better spacing */
}

.uiverse .wrapper span {
  display: inline-block;
  position: relative;
  z-index: 1;
  top: -3px;
}

.uiverse:hover {
  /* Subtle tone shift on hover */
  transition:
    filter 0.3s ease,
    box-shadow 0.3s ease;
  filter: brightness(1.1);
}

.uiverse .wrapper .circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px; /* Doubled the size */
  height: 80px; /* Doubled the size */
  border-radius: 50%;
  filter: blur(var(--blur, 16px)); /* Increased blur */
  background: var(--background, transparent);
  transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
  animation: var(--animation, none) var(--duration) var(--easing) infinite;
}

/* Update circle colors and blur if necessary */
.uiverse .wrapper .circle.circle-1,
.uiverse .wrapper .circle.circle-9,
.uiverse .wrapper .circle.circle-10 {
  --background: var(--c-color-4);
}

.uiverse .wrapper .circle.circle-3,
.uiverse .wrapper .circle.circle-4 {
  --background: var(--c-color-2);
  --blur: 28px; /* Doubled blur */
}

.uiverse .wrapper .circle.circle-5,
.uiverse .wrapper .circle.circle-6 {
  --background: var(--c-color-3);
  --blur: 32px; /* Doubled blur */
}

.uiverse .wrapper .circle.circle-2,
.uiverse .wrapper .circle.circle-7,
.uiverse .wrapper .circle.circle-8,
.uiverse .wrapper .circle.circle-11,
.uiverse .wrapper .circle.circle-12 {
  --background: var(--c-color-1);
  --blur: 24px; /* Doubled blur */
}

/* Adjust circle positions */
.uiverse .wrapper .circle.circle-1 {
  --x: 0;
  --y: -80px; /* Doubled y */
  --animation: circle-1;
}

.uiverse .wrapper .circle.circle-2 {
  --x: 240px; /* Increased from 190px for better right-side coverage */
  --y: 20px;
  --animation: circle-2;
}

.uiverse .wrapper .circle.circle-3 {
  --x: -30px; /* Increased negative x */
  --y: -30px; /* Increased negative y */
  --animation: circle-3;
}

.uiverse .wrapper .circle.circle-4 {
  --x: 200px; /* Increased from 160px */
  --y: -30px;
  --animation: circle-4;
}

.uiverse .wrapper .circle.circle-5 {
  --x: 30px; /* Increased x */
  --y: -10px; /* Increased y */
  --animation: circle-5;
}

.uiverse .wrapper .circle.circle-6 {
  --x: 180px; /* Increased from 140px */
  --y: 32px;
  --animation: circle-6;
}

.uiverse .wrapper .circle.circle-7 {
  --x: 20px; /* Increased x */
  --y: 56px; /* Increased y */
  --animation: circle-7;
}

.uiverse .wrapper .circle.circle-8 {
  --x: 70px; /* Increased x */
  --y: -10px; /* Increased y */
  --animation: circle-8;
}

.uiverse .wrapper .circle.circle-9 {
  --x: 40px; /* Increased x */
  --y: -30px; /* Increased y */
  --animation: circle-9;
}

.uiverse .wrapper .circle.circle-10 {
  --x: 160px; /* Increased from 130px */
  --y: 32px;
  --animation: circle-10;
}

.uiverse .wrapper .circle.circle-11 {
  --x: 16px; /* Increased x */
  --y: 16px; /* Increased y */
  --animation: circle-11;
}

.uiverse .wrapper .circle.circle-12 {
  --blur: 28px;
  --x: 170px; /* Increased from 130px */
  --y: 16px;
  --animation: circle-12;
}

/* Updated keyframes for circles */
/* Ensure animations still look smooth with increased size */
@keyframes circle-1 {
  33% {
    transform: translate(0px, 32px) translateZ(0);
  }

  66% {
    transform: translate(24px, 128px) translateZ(0);
  }
}

@keyframes circle-2 {
  33% {
    transform: translate(200px, -20px) translateZ(0); /* Adjusted from 160px */
  }

  66% {
    transform: translate(180px, -96px) translateZ(0); /* Adjusted from 144px */
  }
}

@keyframes circle-3 {
  33% {
    transform: translate(40px, 24px) translateZ(0);
  }

  66% {
    transform: translate(24px, 8px) translateZ(0);
  }
}

@keyframes circle-4 {
  33% {
    transform: translate(180px, -24px) translateZ(0); /* Adjusted from 152px */
  }

  66% {
    transform: translate(260px, -16px) translateZ(0); /* Adjusted from 224px */
  }
}

@keyframes circle-5 {
  33% {
    transform: translate(168px, 56px) translateZ(0);
  }

  66% {
    transform: translate(80px, -64px) translateZ(0);
  }
}

@keyframes circle-6 {
  33% {
    transform: translate(140px, -32px) translateZ(0); /* Adjusted from 56px */
  }

  66% {
    transform: translate(200px, -112px) translateZ(0); /* Adjusted from 152px */
  }
}

@keyframes circle-7 {
  33% {
    transform: translate(16px, 56px) translateZ(0);
  }

  66% {
    transform: translate(40px, -120px) translateZ(0);
  }
}

@keyframes circle-8 {
  33% {
    transform: translate(64px, -8px) translateZ(0);
  }

  66% {
    transform: translate(112px, -40px) translateZ(0);
  }
}

@keyframes circle-9 {
  33% {
    transform: translate(40px, -24px) translateZ(0);
  }

  66% {
    transform: translate(160px, -16px) translateZ(0);
  }
}

@keyframes circle-10 {
  33% {
    transform: translate(180px, 40px) translateZ(0); /* Adjusted from 136px */
  }

  66% {
    transform: translate(240px, 56px) translateZ(0); /* Adjusted from 200px */
  }
}

@keyframes circle-11 {
  33% {
    transform: translate(16px, 16px) translateZ(0);
  }

  66% {
    transform: translate(136px, 40px) translateZ(0);
  }
}

@keyframes circle-12 {
  33% {
    transform: translate(150px, 0px) translateZ(0); /* Adjusted from 112px */
  }

  66% {
    transform: translate(160px, -64px) translateZ(0); /* Adjusted from 120px */
  }
}

.uiverse:before {
  content: "";
  pointer-events: none;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 48px; /* Match button border-radius */
  box-shadow:
    inset 0 6px 24px var(--c-shadow-inset-top),
    /* Increased shadow */ inset 0 -6px 8px var(--c-shadow-inset-bottom); /* Increased shadow */
}

.uiverse .wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  border-radius: 48px; /* Match button border-radius */
  min-width: 340px; /* Updated min-width */
  padding: 12px 0; /* Increased padding */
  position: relative; /* Ensure positioning context for circles */
  height: 50px; /* Increased height for better spacing */
}

.uiverse .wrapper span {
  display: inline-block;
  position: relative;
  z-index: 1;
  top: -3px;
}

.uiverse:hover {
  /* Subtle tone shift on hover */
  transition:
    filter 0.3s ease,
    box-shadow 0.3s ease;
  filter: brightness(1.09);
}

.uiverse .wrapper .circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px; /* Doubled the size */
  height: 80px; /* Doubled the size */
  border-radius: 50%;
  filter: blur(var(--blur, 16px)); /* Increased blur */
  background: var(--background, transparent);
  transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
  animation: var(--animation, none) var(--duration) var(--easing) infinite;
}

/* Update circle colors and blur if necessary */
.uiverse .wrapper .circle.circle-1,
.uiverse .wrapper .circle.circle-9,
.uiverse .wrapper .circle.circle-10 {
  --background: var(--c-color-4);
}

.uiverse .wrapper .circle.circle-3,
.uiverse .wrapper .circle.circle-4 {
  --background: var(--c-color-2);
  --blur: 28px; /* Doubled blur */
}

.uiverse .wrapper .circle.circle-5,
.uiverse .wrapper .circle.circle-6 {
  --background: var(--c-color-3);
  --blur: 32px; /* Doubled blur */
}

.uiverse .wrapper .circle.circle-2,
.uiverse .wrapper .circle.circle-7,
.uiverse .wrapper .circle.circle-8,
.uiverse .wrapper .circle.circle-11,
.uiverse .wrapper .circle.circle-12 {
  --background: var(--c-color-1);
  --blur: 24px; /* Doubled blur */
}

/* Adjust circle positions */
.uiverse .wrapper .circle.circle-1 {
  --x: 0;
  --y: -80px; /* Doubled y */
  --animation: circle-1;
}

.uiverse .wrapper .circle.circle-2 {
  --x: 240px; /* Increased from 190px for better right-side coverage */
  --y: 20px;
  --animation: circle-2;
}

.uiverse .wrapper .circle.circle-3 {
  --x: -30px; /* Increased negative x */
  --y: -30px; /* Increased negative y */
  --animation: circle-3;
}

.uiverse .wrapper .circle.circle-4 {
  --x: 200px; /* Increased from 160px */
  --y: -30px;
  --animation: circle-4;
}

.uiverse .wrapper .circle.circle-5 {
  --x: 30px; /* Increased x */
  --y: -10px; /* Increased y */
  --animation: circle-5;
}

.uiverse .wrapper .circle.circle-6 {
  --x: 180px; /* Increased from 140px */
  --y: 32px;
  --animation: circle-6;
}

.uiverse .wrapper .circle.circle-7 {
  --x: 20px; /* Increased x */
  --y: 56px; /* Increased y */
  --animation: circle-7;
}

.uiverse .wrapper .circle.circle-8 {
  --x: 70px; /* Increased x */
  --y: -10px; /* Increased y */
  --animation: circle-8;
}

.uiverse .wrapper .circle.circle-9 {
  --x: 40px; /* Increased x */
  --y: -30px; /* Increased y */
  --animation: circle-9;
}

.uiverse .wrapper .circle.circle-10 {
  --x: 160px; /* Increased from 130px */
  --y: 32px;
  --animation: circle-10;
}

.uiverse .wrapper .circle.circle-11 {
  --x: 16px; /* Increased x */
  --y: 16px; /* Increased y */
  --animation: circle-11;
}

.uiverse .wrapper .circle.circle-12 {
  --blur: 28px;
  --x: 170px; /* Increased from 130px */
  --y: 16px;
  --animation: circle-12;
}

/* Updated keyframes for circles */
/* Ensure animations still look smooth with increased size */
@keyframes circle-1 {
  33% {
    transform: translate(0px, 32px) translateZ(0);
  }

  66% {
    transform: translate(24px, 128px) translateZ(0);
  }
}

@keyframes circle-2 {
  33% {
    transform: translate(200px, -20px) translateZ(0); /* Adjusted from 160px */
  }

  66% {
    transform: translate(180px, -96px) translateZ(0); /* Adjusted from 144px */
  }
}

@keyframes circle-3 {
  33% {
    transform: translate(40px, 24px) translateZ(0);
  }

  66% {
    transform: translate(24px, 8px) translateZ(0);
  }
}

@keyframes circle-4 {
  33% {
    transform: translate(180px, -24px) translateZ(0); /* Adjusted from 152px */
  }

  66% {
    transform: translate(260px, -16px) translateZ(0); /* Adjusted from 224px */
  }
}

@keyframes circle-5 {
  33% {
    transform: translate(168px, 56px) translateZ(0);
  }

  66% {
    transform: translate(80px, -64px) translateZ(0);
  }
}

@keyframes circle-6 {
  33% {
    transform: translate(140px, -32px) translateZ(0); /* Adjusted from 56px */
  }

  66% {
    transform: translate(200px, -112px) translateZ(0); /* Adjusted from 152px */
  }
}

@keyframes circle-7 {
  33% {
    transform: translate(16px, 56px) translateZ(0);
  }

  66% {
    transform: translate(40px, -120px) translateZ(0);
  }
}

@keyframes circle-8 {
  33% {
    transform: translate(64px, -8px) translateZ(0);
  }

  66% {
    transform: translate(112px, -40px) translateZ(0);
  }
}

@keyframes circle-9 {
  33% {
    transform: translate(40px, -24px) translateZ(0);
  }

  66% {
    transform: translate(160px, -16px) translateZ(0);
  }
}

@keyframes circle-10 {
  33% {
    transform: translate(180px, 40px) translateZ(0); /* Adjusted from 136px */
  }

  66% {
    transform: translate(240px, 56px) translateZ(0); /* Adjusted from 200px */
  }
}

@keyframes circle-11 {
  33% {
    transform: translate(16px, 16px) translateZ(0);
  }

  66% {
    transform: translate(136px, 40px) translateZ(0);
  }
}

@keyframes circle-12 {
  33% {
    transform: translate(150px, 0px) translateZ(0); /* Adjusted from 112px */
  }

  66% {
    transform: translate(160px, -64px) translateZ(0); /* Adjusted from 120px */
  }
}

.uiverse .wrapper span i {
  margin-right: 8px;
  font-size: 22px;
  vertical-align: middle;
  position: relative;
  top: 1.8px;
}

/* Add mobile responsiveness */
@media screen and (max-width: 768px) {
  .uiverse {
    /* position: fixed; */
    /* bottom: 110px; */
  }
}
