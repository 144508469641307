.terms-of-use {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .terms-of-use h1 {
    font-size: 2.5em;
    color: #4a148c;
    margin-bottom: 20px;
  }
  
  .terms-of-use h2 {
    font-size: 1.8em;
    color: #6a1b9a;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .terms-of-use p {
    margin-bottom: 15px;
  }
  
  .terms-page-wrapper {
    padding-top: 60px; /* Adjust this value to match your navbar height */
    min-height: 100vh;
    background-color: #f5f5f5; /* Light background color for better readability */
  }