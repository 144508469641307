.accountPage {
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  padding-top: 60px;
}

.error {
  color: #ff4d4d;
}

.success {
  color: #4caf50;
}

@media screen and (max-width: 768px) {
  .accountPage {
    padding-top: 60px;
  }

  .userIcon {
    display: none;
  }
}
