.controlsContainer {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1), 0 0 20px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 340px;
  transition: all 0.3s ease;
}

.controlsContainer.adaptive {
  background: rgba(83, 82, 82, 0.6);
  backdrop-filter: blur(10px);
  border: 1.2px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}

.dividerContainer {
  display: flex;
  align-items: center;
  margin: 15.5px 0;
}

@media (max-width: 768px) {
  .dividerContainer {
    display: none;
  }
}

.dividerContainer.adaptive .divider {
  background: rgba(255, 255, 255, 0.3);
}

.divider {
  flex-grow: 1;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}

.globeIcon {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.515);
  margin: 0 10px;
  transform: translate(1px, 2.38px);
}

.globeIcon.adaptive {
  color: rgba(255, 255, 255, 0.7);
}
