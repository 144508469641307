.container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  animation: fadeIn 0.6s ease-in-out forwards;
}

.borderContainer {
  position: relative;
  width: 26vw;
  height: 26vw;
  pointer-events: none;
}

@keyframes rotateClockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateCounterClockwise {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.outerRing {
  animation: rotateClockwise 15s linear infinite;
}

.innerRing {
  animation: rotateCounterClockwise 13s linear infinite;
}

@media (max-width: 600px) {
  .borderContainer {
    width: 104vw;
    height: 104vw;
  }
}

.borderContainer canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 68.5%;
  height: 68.5%;
  border-radius: 50%;
  pointer-events: none;
  opacity: .95;
}

.loadingText {
  color: white;
  font-size: 1.38rem;
  text-align: center;
  margin-top: -3.25rem;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 0.5) 40%
  );
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: 
    fadeIn 0.95s ease-in-out forwards,
    shine 1.6s linear infinite 0.6s;
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
