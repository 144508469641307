.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  padding: 0 10px;
  position: relative;
  background-image: url("../../images/oooscillate.svg"),
    url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" viewBox="0 0 700 700" width="700" height="700" opacity="1"><defs><linearGradient gradientTransform="rotate(45, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="ffflux-gradient"><stop stop-color="hsl(267, 59%, 41%)" stop-opacity="1" offset="0%" /><stop stop-color="hsl(250, 62%, 49%)" stop-opacity="1" offset="50%" /><stop stop-color="hsl(227, 64%, 56%)" stop-opacity="1" offset="100%" /></linearGradient><filter id="ffflux-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feTurbulence type="fractalNoise" baseFrequency="0.005 0.003" numOctaves="2" seed="41" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence" /><feGaussianBlur stdDeviation="41 10" x="0%" y="0%" width="100%" height="100%" in="turbulence" edgeMode="duplicate" result="blur" /><feBlend mode="color" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" in2="blur" result="blend" /></filter></defs><rect width="700" height="700" fill="%23010101" /><rect width="700" height="700" fill="url(%23ffflux-gradient)" filter="url(%23ffflux-filter)" opacity="0.5" /></svg>');
  background-size: cover, cover;
  background-position:
    center 485px,
    center;
  background-repeat: no-repeat, no-repeat;
  background-color: #010101; /* Fallback color */
  position: relative;
  z-index: 1;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin-top: 20px;
}

.icon-button svg {
  width: 50px;
  height: 50px;
  fill: white;
  transition: transform 0.2s ease-in-out;
}

.icon-button:hover svg {
  transform: scale(1.1);
}

.examples {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto 10px;
}

.example-box {
  position: relative;
  width: calc(50% - 10px);
  padding: 15px;
  border-radius: 10px;
  margin: 0 5px;
  font-size: 16px;
  line-height: 1.4;
  color: #dddadb;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-shadow: 0.15px 0.15px 0 #dddadb;
  cursor: pointer;
}

.example-box.purple {
  border: 2px solid transparent;
  border-radius: 10px;
  background-color: rgba(135, 108, 180, 0.2);
  box-shadow:
    0 0 0 1px #8a6fb5,
    0 4px 6px rgba(135, 108, 180, 0.4);
  background-clip: padding-box;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 700px;
}

.button-container #story-style-menu {
  margin-left: 5px;
}

.dropdown-container {
  position: relative;
  width: 120px;
}

.dropdown-container.art-styles::before {
  content: "Visuals";
  position: absolute;
  top: -8px;
  left: 6px;
  background-color: #1a1a1a;
  padding: 0 5px;
  color: #a179de;
  font-size: 12.8px;
}

.dropdown-arrow {
  border: solid #ffffcc;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.art-styles-dropdown-content {
  display: none;
  position: absolute;
  background-image: linear-gradient(to right, #222, #2b2b2b);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 8px;
  z-index: 1;
  gap: 5px;
  width: 500px;
  box-sizing: border-box;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  top: 41.5px;
}

.style-label {
  display: block;
  margin-bottom: 5px;
  color: white;
  font-size: 15px;
  text-align: left;
  width: 100%;
  padding-left: 10px;
}

.style-image {
  width: 85%;
  height: auto;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: 0.2s;
}

.style-option .style-image {
  width: 85%;
  height: auto;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition:
    outline 0.1s ease-in-out,
    transform 0.1s ease-in-out;
}

.dropdown-container.mode::before {
  content: "Presets";
  position: absolute;
  top: -8px;
  left: 6px;
  background-color: #1a1a1a;
  padding: 0 5px;
  color: #a179de;
  font-size: 12.8px;
}

.dropdown-container.length::before {
  content: "Length";
  position: absolute;
  top: -8px;
  left: 6px;
  background-color: #1a1a1a;
  padding: 0 5px;
  color: #a179de;
  font-size: 12.8px;
}

.dropdown-container.voice::before {
  content: "Voice";
  position: absolute;
  top: -8px;
  left: 6px;
  background-color: #1a1a1a;
  padding: 0 5px;
  color: #a179de;
  font-size: 12.8px;
}

.dropdown-selected {
  background-color: transparent;
  border: 2px solid rgb(126, 79, 184);
  border-radius: 9.2px;
  padding: 13.8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffcc;
  font-weight: bold;
  font-size: 13.2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* Add ellipsis when text overflows */
  max-width: 110.7px;
  width: 100%;
}

.dropdown-options {
  display: none;
  position: absolute;
  background-color: #222;
  border-radius: 8px;
  width: 100%;
  max-height: 200px; /* Limit the height to show only 5-7 items */
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 1;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  font-size: 13px;
  top: 52px;
}

.dropdown-options:hover {
  overflow-y: auto;
}

.dropdown-options::-webkit-scrollbar {
  width: 8px;
}

.dropdown-options::-webkit-scrollbar-track {
  background: transparent;
}

.dropdown-options::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
  border: 2px solid transparent;
}

.dropdown-options li {
  padding: 10px;
  cursor: pointer;
  list-style-type: none;
  color: #dab6fc;
  text-align: left;
  background-color: transparent;
  transition: background-color 0.15s;
  box-sizing: border-box;
}

.generateButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.disclaimer {
  width: 100%;
  text-align: left;
  font-size: 0.7875rem;
  line-height: 0.8925rem;
  color: #c2c1c1;
  margin: 2px 0 5px 10px;
  font-family: "Roboto", sans-serif;
  margin-left: 15px;
  top: 265px;
  z-index: 999;
  position: absolute;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.loader {
  position: relative;
  width: 227.5px;
  height: 227.5px;
  border-radius: 50%;
  background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
  animation: loaderAnimate 1.44s linear infinite;
  opacity: 0;
  transition: opacity 0.85s ease-in-out;
}

.loader.loading {
  opacity: 1;
}

.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
}

.loader span:nth-child(1) {
  filter: blur(3px); /* Reduced for smaller glow */
}

.loader span:nth-child(2) {
  filter: blur(6px); /* Reduced for smaller glow */
}

.loader span:nth-child(3) {
  filter: blur(12px); /* Reduced for smaller glow */
}

.loader span:nth-child(4) {
  filter: blur(24px); /* Reduced for smaller glow */
}

.loader:after {
  content: "";
  position: absolute;
  top: 6.5px; /* Adjusted proportionally */
  left: 6.5px; /* Adjusted proportionally */
  right: 6.5px; /* Adjusted proportionally */
  bottom: 6.5px; /* Adjusted proportionally */
  background: #1a1a1a;
  border: solid rgb(214, 214, 214) 6.5px; /* Adjusted proportionally */
  border-radius: 50%;
}

@keyframes loaderAnimate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-in {
  animation: localFadeIn 1s ease-out;
}

@keyframes localFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.panning-image {
  width: 125%;
  max-width: 125%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  object-fit: cover;
  /* animation: pan 13s linear infinite; */
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border-radius: 24px;
}

.panning-image.active {
  display: block;
}

.panning-image.paused {
  animation-play-state: paused !important;
}

.video-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.glossy-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  backdrop-filter: blur(10px);
  z-index: 510;
}

.back-button {
  color: rgb(126, 79, 184);
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 20px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  text-align: center;
  margin-top: 10px;
}

.back-button:hover {
  background-color: rgba(126, 79, 184, 0.1);
  color: rgb(255, 255, 255);
}

.back-button:active {
  background-color: rgba(126, 79, 184, 0.2);
  color: rgb(255, 255, 255);
}

.back-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(126, 79, 184, 0.5);
}

.back-button svg {
  margin-right: 8px;
  display: inline;
  align-items: center;
  vertical-align: top;
}

.back-button svg path {
  transition: stroke 0.3s ease;
  margin-right: 8px;
}

.back-button:hover svg path {
  stroke: rgb(255, 255, 255);
}

.continue-container {
  width: 100%;
  padding-top: 20px;
  /* display: none; */
}

.continue-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.continue-input {
  background-color: #2e2e2e;
  border: none;
  outline: none;
  color: #e6e6e6;
  padding: 10px;
  border-radius: 30px;
  width: 100%;
  height: 45px;
  padding-right: 55px;
  padding-left: 20px;
  font-size: 16px;
}

.submit-continue {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #4a148c;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 0px;
  border-radius: 30px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  /* line-height: 40px; */
}

.submit-continue:hover {
  background-color: #6a1b9a;
}

.submit-continue:active {
  background-color: #3f0e62;
  /* transform: scale(0.95); */
}

.continue-suggestions-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.suggestion-chip {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: 2px solid #4a148c; /* Tovera brand purple */
  color: #ffffff; /* Matching text color */
  padding: 6px 12px;
  border-radius: 16px; /* Rounded corners for chip-like appearance */
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.suggestion-chip:hover {
  background-color: #6a1b9a;
}

.suggestion-chip:active {
  background-color: #3f0e62;
  transform: scale(0.95);
}

.controls-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

#controls-container-inner {
  display: none;
  z-index: 100;
  cursor: pointer;
  padding: 10px 20px;
}
#image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  width: 75vh;
  height: 75vh;
  max-height: 100vh;
  overflow: hidden;
  border: none;
  position: relative;
  object-fit: cover;
  z-index: 510;
  transition: height 0.5s ease-in-out;
  border-radius: 27px;
}

#image-container:hover #controls-container-inner {
  display: block;
}

#progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px; /* Thin progress bar */
  background: radial-gradient(
    circle,
    rgba(95, 98, 255, 1) 15%,
    rgba(118, 96, 255, 1) 45%,
    rgba(131, 97, 255, 1) 80%
  );
  width: 0%; /* Start with 0 width */
  z-index: 500;
  transition: width 0.5s linear; /* Smooth transition for width changes */
  display: none; /* Hide the progress bar initially */
}

#image-container:hover #progress-bar {
  display: block; /* Show the progress bar on hover */
}

#play-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

#pause-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

#play-button,
#pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 150;
  cursor: default; /* Change cursor to default */
}

#continue-button,
#new-button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

#continue-input-container {
  display: flex;
  justify-content: center; /* Center the input and button horizontally */
  gap: 10px; /* Space between input field and button */
}

#continue-input {
  padding: 5px;
  width: 50%; /* Adjust width as necessary */
}

#submit-continue {
  padding: 5px 15px;
}

#subtitle-toggle {
  vertical-align: middle;
}

#subtitle-toggle-label {
  vertical-align: middle;
}

#new-continue-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

#voiceover-audio {
  display: none;
}

#background-music {
  display: none;
}

#story-form textarea,
#story-form input[type="text"] {
  width: 100%;
  height: 24.5vh;
  padding: 10px;
  text-align: left;
  font-size: 16px;
  border-radius: 10px;
  background-color: #2e2e2e;
  border: 1.1px solid #4a4a4a;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
}

#story-form textarea:focus,
#story-form input[type="text"]:focus {
  outline: none;
  border-color: #8e6bc2;
  box-shadow: 0 0 0 2px rgba(161, 121, 222, 0.2);
}

#storyPrompt {
  flex-grow: 1;
  padding: 10px 40px 40px 40px;
  box-sizing: border-box;
  margin-bottom: 0;
  background-color: #2e2e2e;
  color: #e6e6e6;
  position: relative;
}

#additional-info {
  height: 8vh !important; /* Overrides other styles regardless of specificity */ /* Height is 5% of the viewport height, which is roughly 1/4 of 20vh */
  background-color: #2e2e2e; /* Balanced background color */
  color: #e6e6e6; /* Balanced text color */
}

#subtitle-container {
  z-index: 1000;
  position: absolute;
  bottom: 27.3%;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  max-width: 75vh;
  text-align: center;
  color: rgb(221, 217, 217);
  font-size: 1.5em;
  font-family: "Trebuchet MS", sans-serif;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
  white-space: normal;
  word-wrap: break-word;
  opacity: 1 !important; /* Force opacity to always be 1 */
  transition: none !important; /* Disable any transitions */
  animation: none !important; /* Disable any animations */
  border-radius: 8px;
}

@media (min-width: 768px) {
  #story-form textarea {
    font-size: 23px; /* Larger font size for wider screens */
  }
}

.dimming-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: none;
  transition: opacity 0.5s ease-in-out; /* Default transition */
}

.dimming-overlay.play {
  transition-duration: 0.4s;
}

.dimming-overlay.pause {
  transition-duration: 0.35s;
}

form {
  width: 100%;
}

/* Mobile styles for HomePage */
@media screen and (max-width: 768px) {
  .home-page {
    max-width: 100vw;
  }

  .initial-ui-container {
    max-width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
  }

  .examples {
    max-width: 100vw;
    margin: 0px 0px 5px 0px;
  }

  .example-box {
    width: calc(50vw - 10px);
    box-sizing: border-box;
  }

  .example-box:last-of-type {
    margin-right: 0;
  }

  /* .preview-input-container { */
  /* position: relative;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1; */
  /* } */

  #story-prompt {
    width: 100%;
    box-sizing: border-box;
    resize: none;
    min-height: 120px;
    flex-grow: 1;
    padding: 10px;
    padding-right: 40px;
    margin-bottom: 10px;
    padding-left: 70px;
  }

  #story-form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .controls-container {
    padding: 5px;
  }

  .video-content {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 60px;
  }

  #image-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
  }

  .panning-image {
    height: 100%;
    object-fit: cover;
  }

  #subtitle-container {
    font-size: 14px;
    padding: 10px;
    bottom: calc(25% + 25px); /* Raised by 25px for mobile */
    width: 80%;
  }

  .back-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .continue-suggestions-container {
    flex-direction: column;
    gap: 0px;
  }

  .suggestion-chip {
    text-align: center;
    justify-content: center;
  }

  .back-button {
    margin-top: 0px;
  }

  #play-button,
  #pause-button {
    transform: translate(-50%, -50%) scale(0.8);
  }

  #progress-bar {
    height: 3px;
  }

  #new-continue-buttons {
    flex-direction: column;
    gap: 10px;
  }

  #continue-button,
  #new-button,
  #download-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

  #continue-input-container {
    flex-direction: column;
    gap: 10px;
  }

  #continue-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

  #submit-continue {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

  #addon-art-style-button,
  #replace-art-style-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
  }
}
