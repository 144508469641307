:root {
  --map-transition-duration: 1000ms;
  --lottie-transition-duration: 150ms;
  --cursor-size: 32px;
  --fade-in-duration: 850ms;
  --ui-fade-in-duration: 300ms;
}

.timeMachineContainer {
  position: relative;
  height: 100vh;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding-right: 0px;
}

.videoContainer {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../../images/searchbg.svg") no-repeat center center fixed;
  background-size: cover;
  box-sizing: border-box;
}

.mapContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity var(--map-transition-duration) ease-in-out;
}

.customCursor {
  cursor:
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 24 24" width="32"><path d="m9.348 3.387 11.087 9.089a1.5 1.5 0 0 1 -0.968 2.674h-3.91a4.1 4.1 0 0 0 -3.267 1.624l-2.356 3.167a1.53 1.53 0 0 1 -2.748-0.842l-.686-14.264a1.8 1.8 0 0 1 2.848-1.448z" fill="%23f5f5f5" stroke="black" stroke-width="1"/></svg>')
      9 3,
    auto !important;
}

.grabbingCursor {
  cursor: grabbing;
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 10;
  opacity: 0.55;
  transition: opacity var(--lottie-transition-duration) ease-in-out;
  pointer-events: none;
}

.fadeIn {
  opacity: 1;
  transition: opacity var(--fade-in-duration) ease-in-out;
}

.fadeOut {
  opacity: 0;
}

.searchButton {
  background-color: rgba(
    142,
    98,
    214,
    0.8
  ); /* Slightly transparent background */
  color: white;
  border: 1.5px solid rgba(159, 118, 200, 0.85); /* Added a noticeable, partially transparent border */
  padding: 10px 20px;
  border-radius: 20px; /* Kept the rounded border radius */
  font-size: 16px;
  font-weight: bold; /* Changed to bold to match the example */
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.1s ease,
    box-shadow 0.3s ease; /* Added box-shadow transition */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  position: relative; /* Added for pseudo-element */
  overflow: hidden; /* Added for pseudo-element */
  margin-top: 8px;
  box-shadow: inset 0 0 12px rgba(191, 163, 242, 0.5); /* Adjusted color to match the button's purple tone */
}

.searchButton:hover {
  background-color: rgba(
    74,
    26,
    122,
    0.9
  ); /* Darker, slightly more opaque on hover */
  transform: scale(1.01); /* Slight scale effect on hover */
  box-shadow: 0 4px 6px rgba(100, 37, 163, 0.3); /* Shadow effect on hover */
}

.searchButton:active {
  background-color: #7b4fcf;
  transform: scale(0.98);
}

.searchButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(161, 121, 222, 0.5);
}

/* If you want to add an icon */
.searchButton svg {
  width: 18px;
  height: 18px;
  fill: currentColor;
}

/* Adding the skew effect */
.searchButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skew(-13deg);
  transition: transform 0.5s ease;
}

.searchButton:hover::before {
  transform: skew(-13deg) translateX(200%);
}

/* Override Mapbox Attribution Styles */
:global(.mapboxgl-ctrl-attrib) {
  opacity: 0.65;
  background-color: rgb(255, 255, 255);
  padding: 4.25px;
  border-radius: 3.4px;
  font-size: 85%;
}

/* Navigation control styles */
/*
:global(.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group) {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 0 10px 10px 0;
}

:global(.mapboxgl-ctrl-group button) {
  width: 36px;
  height: 36px;
}

:global(.mapboxgl-ctrl-group button:focus) {
  box-shadow: none;
}

:global(.mapboxgl-ctrl-group button:hover) {
  background-color: rgba(0, 0, 0, 0.05);
}

:global(.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon),
:global(.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon) {
  background-image: none !important;
  background-color: transparent;
}

:global(.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon::before) {
  content: '+';
  font-size: 24px;
  font-weight: bold;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

:global(.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon::before) {
  content: '−';
  font-size: 24px;
  font-weight: bold;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
*/

.timeMachineControls {
  position: absolute;
  bottom: 7%;
  right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity var(--ui-fade-in-duration) ease-in-out,
    visibility var(--ui-fade-in-duration) ease-in-out;
}

.timeMachineControls.visible {
  opacity: 1;
  visibility: visible;
}

/* New class to raise the controls */
.raisedControls {
  bottom: calc(7% + 305px); /* Raise by 50px */
}

.combinedOutputImage {
  border-radius: 100px;
  z-index: 1000;
  width: 340px;
  height: auto;
  border: 2px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.3);
  transition: opacity var(--ui-fade-in-duration) ease-in-out;
  margin-bottom: 20px;
}

.timeMachineContainer {
  position: relative;
  height: 100vh;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding-right: 0px;
}

:global(.time-adjustment-container) {
  position: relative;
  opacity: 0; /* Hide initially */
  transition: opacity var(--fade-in-duration) ease-in-out;
}

/* Styles for when the year adjustment container is visible */
:global(.time-adjustment-container.visible) {
  opacity: 1;
}

.timeAdjustmentContainer {
  position: relative;
  transition: opacity var(--ui-fade-in-duration) ease-in-out;
  margin-bottom: 20px;
}

.tmAssistantWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  width: 70%;
}

.cardsContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 100px; */
}

.cardsBackgroundContainer {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.04) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  border-radius: 1.25rem;
  padding: 1.25rem 0.5rem;
  position: relative;
  z-index: 1000;

  /* Add subtle border glow effect */
  border: 1.3px solid rgba(222, 215, 231, 0);
  box-shadow: 
    /* Outer glow */
    0 0 25px rgba(0, 0, 0, 0.5),
    /* Inner glow */ inset 0 0 20px rgba(229, 224, 235, 0.15),
    /* Subtle edge highlight */ 0 0 1px rgba(255, 255, 255, 0.15);

  /* Add pseudo-element for additional effects */
  &::before {
    content: "";
    position: absolute;
    inset: -1px;
    border-radius: 1.25rem;
    padding: 1px;
    background: linear-gradient(
      135deg,
      rgba(161, 121, 222, 0.2) 0%,
      rgba(54, 117, 221, 0.2) 50%,
      rgba(161, 121, 222, 0.2) 100%
    );
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
}

.closeButton {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(161, 121, 222, 0.3);
  backdrop-filter: blur(8px);
  color: white;
  border: 1.5px solid rgba(161, 121, 222, 0.4);
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1001;
  box-shadow:
    inset 0 0 8px rgba(161, 121, 222, 0.3),
    0 2px 8px rgba(0, 0, 0, 0.2);
}

.closeButton:hover {
  background-color: rgba(161, 121, 222, 0.38);
  box-shadow: inset 0 0 12px rgba(161, 121, 222, 0.52);
}

.closeButton:focus {
  outline: none;
  box-shadow:
    inset 0 0 12px rgba(161, 121, 222, 0.4),
    0 0 0 2px rgba(255, 255, 255, 0.2),
    0 4px 12px rgba(0, 0, 0, 0.3);
}

/* New Classes for Hiding and Fading In UI Elements */
.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--ui-fade-in-duration) ease-in-out;
}

.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--ui-fade-in-duration) ease-in-out;
}

.sparkleIcon {
  width: 21.4px;
  height: 21.4px;
  fill: currentColor;
}

.sparkleIcon {
  font-size: 19.26px;
}

.dictationButtonContainer {
  position: absolute;
  bottom: 105px;
  right: 134px;
  z-index: 998;
  transition: opacity var(--fade-in-duration) ease-in-out;
}

/* Hiding dictation container on mobile until we identify and fix the issue */
@media (max-width: 768px) {
  .dictationButtonContainer {
    display: none; /* Remove this when we fix things on mobile */
  }
}

.countryDisplayWrapper {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Putting CountryDisplay within timeMachineControls */
.timeMachineControls .countryDisplayContainer {
  margin-bottom: 20px;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.25s ease-in-out,
    visibility 0.25s ease-in-out;
  will-change: opacity, visibility;
}

.overlay.preloaded {
  visibility: visible;
  opacity: 0;
}

.overlay.visible {
  opacity: 1;
  visibility: visible;
}

.cardsContainer {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.cardsContainer.visible {
  opacity: 1;
}

.cardsBackgroundContainer {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.cardsBackgroundContainer.visible {
  opacity: 1;
}

.closeButton {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.closeButton.visible {
  opacity: 1;
}

.randomButton {
  position: absolute;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(20px);
  border: 1.4px solid rgba(255, 255, 255, 0.72);
  box-shadow:
    0 4px 15px rgba(0, 0, 0, 0.1),
    0 0 20px rgba(248, 215, 83, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 89;
}

.randomButton:hover {
  background: rgba(255, 255, 255, 0.55);
  box-shadow:
    0 6px 20px rgba(0, 0, 0, 0.2),
    0 0 25px rgba(250, 216, 83, 0.5);
  border-color: rgba(255, 255, 255, 0.75);
}

.diceIcon {
  width: 33px;
  height: 33px;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .timeMachineControls {
    position: absolute;
    top: 55px;
    right: 50%;
    transform: translateX(50%);
    /* width: 272px;
    max-width: 272px; */
    width: 100%;
    /* height: 90vh; */
    bottom: auto;
    /* Add display flex and gap */
    display: flex;
    flex-direction: column;
    /* gap: 250px; */
    justify-content: space-between;
    align-items: space-between;
  }

  .tmAssistantWrapper {
    display: none;
  }

  .combinedOutputImage {
    width: 272px;
  }

  .timeAdjustmentContainer {
    margin-bottom: 0;
  }

  .cardsContainer {
    height: calc(100vh - 20px); /* Adjust to leave 20px gap at bottom */
    padding: 16px;
    justify-content: flex-start;
    padding-top: 100px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  /* Add scroll indicator */
  .cardsContainer::after {
    content: "";
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    animation: pulseIndicator 2s infinite;
  }

  @keyframes pulseIndicator {
    0%,
    100% {
      opacity: 0.3;
      transform: translateX(-50%) scaleX(1);
    }
    50% {
      opacity: 0.6;
      transform: translateX(-50%) scaleX(1.2);
    }
  }

  .cardsBackgroundContainer {
    width: 90%;
    max-width: 320px;
    padding: 16px;
    margin: 0 auto;
    margin-bottom: 40px; /* Add space for scroll indicator */
  }

  /* Ensure the map container fills the viewport */
  .mapContainer {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  /* Adjust overlay to match new container height */
  .overlay {
    height: calc(100vh - 20px);
  }
}
