.skeleton-card {
  width: 320px;
  height: 320px; /* Reduced height by 20% */
  background: transparent;
  position: relative;
}

.loading-animation {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: relative;
  background: linear-gradient(
    -45deg,
    rgba(35, 166, 213, 0.115) 0%,
    rgba(35, 213, 171, 0.115) 25%,
    rgba(64, 224, 208, 0.115) 50%,
    rgba(0, 191, 255, 0.115) 75%,
    rgba(35, 166, 213, 0.115) 100%
  );
  background-size: 400% 400%;
  animation:
    loadingAnimation 4.25s ease infinite,
    loadingFade 1.65s ease-in-out infinite,
    pulse 1.85s ease-in-out infinite;
  backdrop-filter: blur(9px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.115);
  border: 1.2px solid rgba(255, 255, 255, 0.12);
}

@keyframes loadingAnimation {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

@keyframes loadingFade {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.44;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.54;
  }
}

.quantum-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.13;
}

.elapsed-time {
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.225);
  font-family: monospace;
  opacity: 0.315;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.225);
  background-color: rgba(0, 0, 0, 0.09);
  padding: 2px 4px;
  border-radius: 4px;
}

.hoverBackground {
  transition: background-color 0.3s ease;
}

.hoverBackground:hover {
  background-color: rgba(255, 255, 255, 0.095);
}

.sparkle-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  opacity: 0.4;
  background-color: rgba(1, 1, 1, 0.35);
  backdrop-filter: blur(20px);
  border-radius: 50%;
  padding: 2px;
}

/* Updated Styles for Ellipsis Indicator */
.titleWrapper {
  position: relative;
}

.ellipsisContainer {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  /* Removed existing absolute positioning to allow dynamic positioning via JS */
}

.ellipsis {
  width: 24px;
  height: 18px;
  border: 1.5px solid rgba(255, 255, 255, 0.085);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.27);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: rgba(46, 103, 194, 0.24);
}

.ellipsis:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 1);
}

@media (max-width: 767px) {
  .skeleton-card {
    width: 100%;
    height: auto;
    aspect-ratio: 16/10;
  }
}

.cardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  backdrop-filter: blur(8px);
  padding: 0.75rem;
  padding-top: 0.25rem;
  transition: colors 0.3s ease-in-out;
  border-bottom: 1px solid rgba(96, 165, 250, 0.5);
  border-left: 1px solid rgba(96, 165, 250, 0.5);
  border-right: 1px solid rgba(96, 165, 250, 0.5);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: rgba(54, 117, 221, 0.17);
  background: linear-gradient(
    135deg,
    rgba(211, 216, 224, 0.07) 0%,
    rgba(54, 117, 221, 0.13) 35%,
    rgba(92, 107, 177, 0.11) 75%,
    rgba(54, 117, 221, 0.10) 100%
  );
  box-shadow: 
    inset 0 0 15px rgba(54, 117, 221, 0.17),
    inset 0 0 25px rgba(211, 216, 224, 0.10),
    inset 0 1px 2px rgba(96, 165, 250, 0.25),
    inset 0 0 8px rgba(96, 165, 250, 0.16);
}

.cardTitle {
  color: white;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0.25rem;
  padding: 0.125rem 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardContent[data-theme='timeMachine'] {
  background: 
    linear-gradient(135deg,
      rgba(211, 216, 224, 0.14) 0%,
      rgba(54, 117, 221, 0.27) 35%,
      rgba(92, 107, 177, 0.22) 75%,
      rgba(54, 117, 221, 0.20) 100%
    ),
    radial-gradient(circle at 50% 100%, 
      rgba(54, 117, 221, 0.25) 0%, 
      transparent 60%
    );
  box-shadow: 
    inset 0 0 15px rgba(54, 117, 221, 0.17),
    inset 0 0 25px rgba(211, 216, 224, 0.10),
    inset 0 -2px 5px rgba(255, 255, 255, 0.05);
}

.generateArrow {
  width: 1.5rem;
  height: 1.5rem;
  color: rgb(243 244 246);
  transition: all 0.3s;
  opacity: 1;
  margin-left: 0.5rem;
}

.generateArrow:hover {
  color: white;
  transform: translateX(0.25rem);
}

.caretIcon {
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.3s ease-in-out;
  transform: translateY(0);
}

.caretIcon:hover {
  color: rgba(255, 255, 255, 0.9);
  transform: translateY(-2px);
}

.group:hover .caretIcon {
  opacity: 0;
  transform: translateY(-4px);
}
