.suggestions-container {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 8px;
  width: 100%;
  max-width: 800px;
  padding: 0 16px 16px;
  box-sizing: border-box;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.suggestions-container::-webkit-scrollbar {
  display: none;
}

.suggestion-pill {
  display: inline-flex;
  align-items: center;
  padding: 12px 24px;
  /* Updated to neutral glassy background */
  background-color: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border: 1.2px solid rgba(255, 255, 255, 0.3);
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  line-height: 1.2;
  text-align: left;
  white-space: nowrap;
  font-weight: 500;
  box-shadow:
    0 4px 24px rgba(0, 0, 0, 0.1),
    inset 0 0 12px rgba(255, 255, 255, 0.05);
}

.suggestion-pill:hover {
  /* Updated hover background to match style */
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow:
    0 8px 32px rgba(0, 0, 0, 0.15),
    inset 0 0 16px rgba(255, 255, 255, 0.08);
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.3));
}

.suggestion-icon {
  margin-right: 12px;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

.suggestion-text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
}

.fade-in-suggestion {
  animation: fadeIn 0.75s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .suggestions-container {
    gap: 8px;
    padding: 0 12px;
  }

  .suggestion-pill {
    padding: 6px 12px;
    font-size: 12px;
  }

  .suggestion-icon {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .suggestions-container {
    gap: 6px;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    width: 75%;
  }

  .suggestion-pill {
    padding: 12px;
    width: 100%;
    font-size: 14px;
  }

  .suggestion-icon {
    font-size: 12px;
  }
}

.add-icon {
  margin-right: 8px;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.45);
  user-select: none;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}
