.dropdownsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 700px;
  margin: -2.5px auto 0;
  position: relative;
  z-index: 5;
  margin-bottom: -15px;
}

.toastPosition {
  top: 50%;
  left: 50%;
  transform: translate(102.5%, -127%);
}

@media (min-width: 769px) and (max-width: 1024px) {
  .toastPosition {
    transform: translate(94.5%, calc(-132% + 20px));
  }
}

@media (max-width: 768px) {
  .dropdownsContainer {
    flex-wrap: wrap;
    gap: 10px;
  }

  .dropdownsContainer > div {
    width: calc(50% - 5px);
  }

  .dropdownsContainer > div:last-child {
    width: 100%;
    margin-top: -22px;
    margin-bottom: 0px;
  }
}

.glassyContainer {
  background: rgba(255, 255, 255, 0.085);
  margin-bottom: 0px;
  /* backdrop-filter: blur(10px); */
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  border: 1.5px solid rgba(255, 255, 255, 0.45);
}

.glassyTextarea {
  background: transparent !important;
  border: none !important;
  backdrop-filter: blur(5px);
  color: #ffffff !important;
}

.glassyTextarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
